// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body,
input,
table,
button {
  font-family: "Nunito", sans-serif !important;
}

html,
body {
  margin: 0;
  height: 100%;
  background-color: #f2f0f7;
}

footer {
  background: #f9f9f9;
}

#root {
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAEA;;;;EAIE,4CAAA;AADF;;AAIA;;EAEE,SAAA;EACA,YAAA;EACA,yBAAA;AADF;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE,eAAA;AAAF","sourcesContent":["@import \"style/Variables.scss\";\n\nbody,\ninput,\ntable,\nbutton {\n  font-family: \"Nunito\", sans-serif !important;\n}\n\nhtml,\nbody {\n  margin: 0;\n  height: 100%;\n  background-color: #f2f0f7;\n}\nfooter {\n  background: #f9f9f9;\n}\n\n#root {\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
